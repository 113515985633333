<template>
  <div>
    <div class="van-main">
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell title="授信管理" is-link to="/credit/list" />
          <van-cell title="用信台账" is-link to="/useCredit/list" />
          <van-cell title="对外担保台账" is-link to="/external/list" />
        </van-cell-group>
      </div>
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Cell, CellGroup } from 'vant'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
